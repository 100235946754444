import React, { useState } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import PostList from './components/PostList';
import PostContent from './components/PostContent';
import UnscramblerInfo from './components/UnscramblerInfo';

function Navigation() {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const isActive = (path) => location.pathname === path;

  return (
    <nav className="bg-white shadow-lg">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex">
            <div className="flex-shrink-0 flex items-center">
              <span className="text-xl font-bold text-indigo-600">Word Unscrambler</span>
            </div>
            <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
              <Link
                to="/"
                className={`${
                  isActive('/')
                    ? 'border-indigo-500 text-gray-900'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                } inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium`}
              >
                Unscrambler
              </Link>
              <Link
                to="/posts"
                className={`${
                  isActive('/posts')
                    ? 'border-indigo-500 text-gray-900'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                } inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium`}
              >
                Posts
              </Link>
            </div>
          </div>
          <div className="-mr-2 flex items-center sm:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
            >
              <span className="sr-only">Open main menu</span>
              {isOpen ? (
                <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              ) : (
                <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                </svg>
              )}
            </button>
          </div>
        </div>
      </div>

      {isOpen && (
        <div className="sm:hidden">
          <div className="pt-2 pb-3 space-y-1">
            <Link
              to="/"
              className={`${
                isActive('/')
                  ? 'bg-indigo-50 border-indigo-500 text-indigo-700'
                  : 'border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700'
              } block pl-3 pr-4 py-2 border-l-4 text-base font-medium`}
            >
              Unscrambler
            </Link>
            <Link
              to="/posts"
              className={`${
                isActive('/posts')
                  ? 'bg-indigo-50 border-indigo-500 text-indigo-700'
                  : 'border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700'
              } block pl-3 pr-4 py-2 border-l-4 text-base font-medium`}
            >
              Posts
            </Link>
          </div>
        </div>
      )}
    </nav>
  );
}

function UnscramblerForm({ onSubmit, loading }) {
  const [word, setWord] = useState('');
  const [dictionary, setDictionary] = useState('');
  const [mustInclude, setMustInclude] = useState('');
  const [startsWith, setStartsWith] = useState('');
  const [endsWith, setEndsWith] = useState('');
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [error, setError] = useState('');

  const handleWordChange = (e) => {
    const input = e.target.value;
    // Remove any spaces and non-alphabetic characters
    const sanitizedInput = input.replace(/[^a-zA-Z]/g, '');
    setWord(sanitizedInput);
    
    if (sanitizedInput !== input) {
      setError('Please enter only a single word without spaces or special characters.');
    } else {
      setError('');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (word.trim()) {
      onSubmit({ word: word.trim(), dictionary, mustInclude, startsWith, endsWith });
    } else {
      setError('Please enter a word to unscramble.');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div>
        <label htmlFor="word" className="block text-sm font-medium text-gray-700">
          Scrambled Word
        </label>
        <input
          id="word"
          type="text"
          value={word}
          onChange={handleWordChange}
          placeholder="Enter a single word"
          required
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
        />
        {error && <p className="mt-2 text-sm text-red-600">{error}</p>}
      </div>

      <div>
        <button
          type="button"
          onClick={() => setShowAdvanced(!showAdvanced)}
          className="text-sm text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline"
        >
          {showAdvanced ? 'Hide Advanced Options' : 'Show Advanced Options'}
        </button>
      </div>

      {showAdvanced && (
        <div className="space-y-4 bg-gray-50 p-4 rounded-md">
          <div>
            <label htmlFor="dictionary" className="block text-sm font-medium text-gray-700">
              Dictionary
            </label>
            <select
              id="dictionary"
              value={dictionary}
              onChange={(e) => setDictionary(e.target.value)}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            >
              <option value="">Any</option>
              <option value="English">English</option>
              <option value="American">American</option>
              <option value="British">British</option>
            </select>
          </div>
          <div>
            <label htmlFor="mustInclude" className="block text-sm font-medium text-gray-700">
              Must Include
            </label>
            <input
              id="mustInclude"
              type="text"
              value={mustInclude}
              onChange={(e) => setMustInclude(e.target.value)}
              placeholder="e.g., a,b,c"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>
          <div>
            <label htmlFor="startsWith" className="block text-sm font-medium text-gray-700">
              Starts With
            </label>
            <input
              id="startsWith"
              type="text"
              value={startsWith}
              onChange={(e) => setStartsWith(e.target.value)}
              placeholder="e.g., a"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>
          <div>
            <label htmlFor="endsWith" className="block text-sm font-medium text-gray-700">
              Ends With
            </label>
            <input
              id="endsWith"
              type="text"
              value={endsWith}
              onChange={(e) => setEndsWith(e.target.value)}
              placeholder="e.g., z"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>
        </div>
      )}

      <div>
        <button
          type="submit"
          disabled={loading || !word.trim() || error}
          className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
        >
          {loading ? 'Unscrambling...' : 'Unscramble'}
        </button>
      </div>
    </form>
  );
}

function App() {
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (formData) => {
    setLoading(true);
    setError(null);
    setResult(null);

    try {
      const response = await axios.post('/unscramble', formData);
      setResult(response.data);
    } catch (err) {
      setError(err.response?.data?.error || 'An error occurred while unscrambling the word.');
      console.error('Detailed error:', err.response?.data?.details || err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Router>
      <div className="min-h-screen bg-gray-100">
        <Helmet>
          <html lang="en" />
          <title>Word Unscrambler - Solve Anagrams and Improve Vocabulary</title>
          <meta name="description" content="Unscramble words, solve anagrams, and expand your vocabulary with our advanced Word Unscrambler tool. Perfect for word games and learning English." />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <link rel="canonical" href={window.location.origin} />
        </Helmet>
        <header>
          <Navigation />
        </header>
        <main className="py-10">
          <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
            <Routes>
              <Route path="/" element={
                <>
                  <Helmet>
                    <title>Word Unscrambler</title>
                  </Helmet>
                  <section className="max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl">
                    <div className="p-8">
                      <h1 className="text-3xl font-bold text-center text-indigo-600 mb-6">Word Unscrambler</h1>
                      <UnscramblerForm onSubmit={handleSubmit} loading={loading} />
                      {error && <p className="mt-4 text-red-600 text-sm" role="alert">{error}</p>}
                      {result && (
                        <div className="mt-8">
                          <h2 className="text-xl font-semibold text-gray-900 mb-4">Unscrambled Words:</h2>
                          {result.results.map((lengthGroup) => (
                            <div key={lengthGroup.length} className="mb-6">
                              <h3 className="text-lg font-medium text-gray-900 mb-2">{lengthGroup.length}-letter words:</h3>
                              <ul className="space-y-2">
                                {lengthGroup.words.map((wordObj, index) => (
                                  <li key={index} className="bg-white shadow overflow-hidden rounded-md px-4 py-3">
                                    <strong className="text-indigo-600">{wordObj.word}</strong>: {wordObj.meaning}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </section>
                  <UnscramblerInfo />
                </>
              } />
              <Route path="/posts" element={
                <>
                  <Helmet>
                    <title>Word Unscrambler - All Posts</title>
                    <meta name="description" content="Browse all unscrambled word posts to improve your vocabulary and word game skills." />
                    <link rel="canonical" href={`${window.location.origin}/posts`} />
                  </Helmet>
                  <PostList />
                </>
              } />
              <Route path="/posts/:slug" element={<PostContent />} />
            </Routes>
          </div>
        </main>
        <footer className="bg-white shadow mt-8 py-4">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <p className="text-center text-gray-500">&copy; 2024 Word Unscrambler. All rights reserved.</p>
          </div>
        </footer>
      </div>
    </Router>
  );
}

export default App;
