import React from 'react';

function UnscramblerInfo() {
  return (
    <div className="bg-white shadow-md rounded-lg p-6 mt-8">
      <h2 className="text-2xl font-bold text-indigo-600 mb-4">About Our Word Unscrambler</h2>
      
      <section className="mb-6">
        <h3 className="text-xl font-semibold text-gray-800 mb-2">What Our Word Unscrambler Can Do</h3>
        <ul className="list-disc pl-5 space-y-2">
          <li>Unscramble words of any length, from 2 to 15 letters</li>
          <li>Provide multiple word possibilities for each scrambled input</li>
          <li>Offer brief definitions for each unscrambled word</li>
          <li>Support various English dictionaries (American, British, etc.)</li>
          <li>Generate and save results for future reference</li>
        </ul>
      </section>

      <section className="mb-6">
        <h3 className="text-xl font-semibold text-gray-800 mb-2">Advanced Features</h3>
        <ul className="list-disc pl-5 space-y-2">
          <li>Dictionary Selection: Choose specific English variants for more accurate results</li>
          <li>Must Include: Specify letters that must be in the unscrambled words</li>
          <li>Starts With / Ends With: Define prefixes or suffixes for targeted unscrambling</li>
          <li>Length-based results: View unscrambled words grouped by their length</li>
          <li>Persistent results: Access previously unscrambled words through our Posts feature</li>
        </ul>
      </section>

      <section className="mb-6">
        <h3 className="text-xl font-semibold text-gray-800 mb-2">What Sets Us Apart</h3>
        <ul className="list-disc pl-5 space-y-2">
          <li>Powered by advanced AI for more accurate and comprehensive results</li>
          <li>Integrated dictionary definitions for immediate word understanding</li>
          <li>Flexible search options to cater to various word game needs</li>
          <li>User-friendly interface with both simple and advanced modes</li>
          <li>Ability to save and revisit unscrambled words, creating a learning resource</li>
        </ul>
      </section>

      <section>
        <h3 className="text-xl font-semibold text-gray-800 mb-2">Perfect For</h3>
        <ul className="list-disc pl-5 space-y-2">
          <li>Word game enthusiasts (Scrabble, Words with Friends, etc.)</li>
          <li>Crossword puzzle solvers</li>
          <li>Students learning English vocabulary</li>
          <li>Writers looking for inspiration</li>
          <li>Anyone curious about word patterns and structures</li>
        </ul>
      </section>
    </div>
  );
}

export default UnscramblerInfo;