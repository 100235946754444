import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import axios from 'axios';

function PostContent() {
  const [post, setPost] = useState({ title: '', metaDescription: '', content: '' });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { slug } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchPost = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`/api/posts/${slug}`);
        setPost(response.data);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching post:', err);
        setError('Failed to fetch post content. Please try again later.');
        setLoading(false);
      }
    };

    fetchPost();
  }, [slug]);

  const handleGoBack = () => {
    navigate('/posts');
  };

  // Construct the canonical URL
  const canonicalUrl = `${window.location.origin}${location.pathname}`;

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64" aria-live="polite" aria-busy="true">
        <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-indigo-500"></div>
        <span className="sr-only">Loading...</span>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-red-600 text-center py-8" role="alert">
        <p>{error}</p>
        <button 
          onClick={handleGoBack} 
          className="mt-4 bg-indigo-600 text-white px-4 py-2 rounded hover:bg-indigo-700 transition-colors"
        >
          Go Back to Posts
        </button>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>{`${post.title} - Word Unscrambler`}</title>
        <meta name="description" content={post.metaDescription} />
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <nav className="mb-8">
          <Link 
            to="/posts" 
            className="text-indigo-600 hover:text-indigo-800 transition-colors"
            aria-label="Back to Posts"
          >
            &larr; Back to Posts
          </Link>
        </nav>
        <article className="bg-white shadow-lg rounded-lg overflow-hidden">
          <div className="p-8">
            <h1 className="text-3xl font-bold text-gray-900 mb-4">{post.title}</h1>
            <div 
              className="prose prose-indigo max-w-none"
              dangerouslySetInnerHTML={{ __html: post.content }}
            />
          </div>
        </article>
      </div>
    </>
  );
}

export default PostContent;